<template>
  <section
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
  >
    <MessageThread
      :companyConfig="companyConfig"
      :userConfig="userConfig"
      :threadInfo="threadInfo"
      :messages="messages"
      :profile="profile"
      :sendMail="getThreadMessages"
    />
  </section>
</template>

<script>
import { fetchMessagesUsingThread } from "../../components/user-integrations/KF-mail/data/messages";
export default {
  props: {
    thread: {
      type: String,
      required: true,
    },
    companyConfig: {
      type: Object,
      required: true,
    },
    userConfig: {
      type: Object,
      required: true,
    },
    threadInfo: {
      type: Object,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
  },
  components: {
    MessageThread: () => import("./message-thread.vue"),
  },
  data() {
    return {
      messages: [],
      loading: false,
      loadingText: " Fetching...",
    };
  },
  mounted() {
    this.loading = true;
    this.getThreadMessages();
  },
  methods: {
    async getThreadMessages() {
      try {
        // Fetch messages and assign them to `inbox`
        let data = await fetchMessagesUsingThread(
          this.companyConfig?.service,
          this.userConfig?._id,
          this.thread
        );
        console.log("email", this.email);
        this.$set(this, "messages", data?.email || []);
        this.loading = false;
      } catch (error) {
        console.error("Failed to fetch messages:", error);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
